<template>
  <div>
    <b-form-row>
      <b-col :cols="inputSize">
        <b-form-group>
          <b-form-checkbox
            id="test-with-security-method"
            name="test-with-security-method"
            v-model="testAPIModel.testWithSecurityMethod"
            @change="onChangeTestWithSecurityMethod()"
          >
            Test with security method
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-form-row>
    <template v-if="testAPIModel.testWithSecurityMethod">
      <b-form-row>
        <b-col :cols="inputSize">
          <b-form-group>
            <div class="floating-select-field">
              <b-form-select
                id="test-api-security-methods-dropdown"
                class="form-control"
                v-model="testAPIModel.securityMethodId"
                @input="testAPIModel.securityMethodId = $event"
                :class="{
                  'is-value-exist': testAPIModel.securityMethodId,
                  'is-invalid': !testAPIModel.securityMethodId && formSubmitted,
                }"
              >
                <b-form-select-option :value="security.id" v-for="(security, index) in securityMethods" :key="index">{{
                  security.methodName
                }}</b-form-select-option>
              </b-form-select>
              <label for="test-api-security-methods-dropdown">Select security method</label>
              <b-form-invalid-feedback class="d-block" v-if="!testAPIModel.securityMethodId && formSubmitted"
                >Security method required.</b-form-invalid-feedback
              >
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { useAxios } from '@/composables/useAxios'
import { ServiceUrls } from '@/utilities/serviceUrls'
import { tenantSpecificURL } from '@/utilities/utilities'
export default {
  name: 'TestWithSecurityMethod',
  setup() {
    const { response, callApi } = useAxios()
    return { response, callApi }
  },
  props: {
    testAPIModel: {
      type: Object,
      required: true,
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
    inputSize: {
      type: Number,
      default: 12,
    },
  },
  computed: {
    ...mapState({
      securityMethods: (state) => state.settings.securityMethods,
    }),
  },
  mounted() {
    this.onChangeTestWithSecurityMethod()
  },
  methods: {
    async onChangeTestWithSecurityMethod() {
      if (this.testAPIModel.testWithSecurityMethod) {
        await this.callApi({ method: 'get', url: tenantSpecificURL(ServiceUrls.getSecurityMethods) })
        if (this.response) {
          this.$store.commit('settings/setSecurityMethods', this.response)
        }
      } else {
        this.testAPIModel.securityMethodId = null
      }
    },
  },
}
</script>
